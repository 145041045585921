import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {sendToVercelAnalytics} from './vitals';
import {ChakraProvider, extendTheme, withDefaultColorScheme} from "@chakra-ui/react";
import {inject as injectAnalytics} from '@vercel/analytics';

const customTheme =
  extendTheme(withDefaultColorScheme({colorScheme: 'blue'}))

const theme = {
  ...customTheme,
  fonts: {
    heading: "'Lato', serif",
    body: "'Lato', serif"
  }
}

injectAnalytics();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App/>
    </ChakraProvider>
  </React.StrictMode>
);

reportWebVitals(sendToVercelAnalytics);
