import {Flex, Heading, Hide, Img, List, ListIcon, ListItem, Text, Tooltip} from "@chakra-ui/react";
import {CheckCircleIcon, InfoOutlineIcon} from "@chakra-ui/icons";
import React from "react";

export const HowToStart = () => (
  <Flex
    id="primeiros-passos"
    p={{base: 4, sm: 4, md: 4, lg: 16, xl: 16}}
    px={{base: 4, md: 24, lg: 24, xl: 24}}
    gap={16}
    bg="#F4F7FA"
    direction="column"
    fontSize="16px"
    fontWeight="semibold"
  >
    <Heading textAlign="center">Veja como é simples começar a usar a Auvi</Heading>
    <List spacing={6}>
      <ListItem>
        <ListIcon as={CheckCircleIcon} fontSize={20} color="blue.500"/>
        Faça um cadastro com informações básicas da sua loja
      </ListItem>
      <ListItem>
        <ListIcon as={CheckCircleIcon} fontSize={20} color="blue.500"/>
        Escaneie um QR Code para configurar o WhatsApp da Loja na plataforma Auvi
      </ListItem>
      <ListItem>
        <ListIcon as={CheckCircleIcon} fontSize={20} color="blue.500"/>
        Compartilhe alguns relatórios da loja para que nossa Inteligência Artificial comece a ser treinada
      </ListItem>
    </List>
    <Img w="250px" m="0 auto" src="onboarding.png"/>
    <Text
      color="blue.500"
      fontWeight="bold"
      fontSize="lg"
      textAlign="center"
    >
      Pronto! Em poucos minutos você já está preparado para potencializar suas vendas
    </Text>
  </Flex>);