import React from "react";
import {Card, CardBody, CardHeader, Divider, Flex, Hide, List, ListItem, Text, Tooltip} from "@chakra-ui/react";
import {CheckCircleIcon, InfoOutlineIcon, TriangleUpIcon} from "@chakra-ui/icons";

const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const CalculationResults = ({
                                     title,
                                     computedData,
                                     growthPercentage,
                                     bgColor,
                                     color,
                                     withIcon = false,
                                     withTooltip = false
                                   }) => {

  const averageBilling = formatter.format(computedData.averageBilling);
  const grossProfit = formatter.format(computedData.grossProfit);
  const monthlyCost = formatter.format(computedData.monthlyCost);
  const storeResult = formatter.format(computedData.storeResult);

  let growthBilling = '-';
  let growthProfit = '-';
  let growthMonthlyCost = '-';
  let growthStoreResult = '-';

  if (growthPercentage) {
    const {averageBilling, grossProfit, monthlyCost, storeResult} = growthPercentage;

    growthBilling = `${averageBilling.toFixed(2)}%`;
    growthProfit = `${grossProfit.toFixed(2)}%`;
    growthMonthlyCost = `${monthlyCost.toFixed(2)}%`;
    growthStoreResult = `${storeResult.toFixed(2)}%`;
  }

  return (
    <Card position="relative" w={{base: "100%"}} minH="630px" bgColor={bgColor}>
      <CardHeader>
        <Flex justifyContent="center" alignItems="center" gap={2} color={color}>
          <Text fontWeight="bold">{title}</Text>
          {withIcon && <CheckCircleIcon/>}
        </Flex>
      </CardHeader>
      <CardBody justifyContent="center" display="flex" flexDirection="column" px={8}>
        <List spacing={9}>
          <ListItem>
            <Flex justifyContent="space-between">
              <Text>Faturamento mensal</Text>
              <Flex direction="column" gap={1}>
                <Text color={color} fontWeight="bold">{averageBilling}</Text>
                <Text
                  color={color}
                  fontSize="12px"
                  textAlign="center"
                >
                  {
                    growthPercentage ?
                      <><TriangleUpIcon color="green.500"/> {growthBilling}</>
                      :
                      '-'
                  }
                </Text>
              </Flex>
            </Flex>
          </ListItem>
          <Divider/>
          <ListItem>
            <Flex justifyContent="space-between">
              <Text>Lucro Bruto mensal</Text>
              <Flex direction="column" gap={1}>
                <Text color={color} fontWeight="bold">{grossProfit}</Text>
                <Text
                  color={color}
                  fontSize="12px"
                  textAlign="center"
                >
                  {
                    growthPercentage ?
                      <><TriangleUpIcon color="green.500"/> {growthProfit}</>
                      :
                      '-'
                  }
                </Text>
              </Flex>
            </Flex>
          </ListItem>
          <Divider/>
          <ListItem>
            <Flex justifyContent="space-between">
              <Text>Custos fixos mensais</Text>
              <Flex direction="column" gap={1} position="relative">
                {withTooltip && (
                  <Hide below="md">
                    <Tooltip hasArrow
                             label="O custo do plano mensal da Auvi foi aplicado. Você pode reduzir o valor total investido ao optar pelo plano trimestral ou semestral.">
                      <InfoOutlineIcon color="blue.600" pos="absolute" right="-20px" top="3px"/>
                    </Tooltip>
                  </Hide>
                )}
                <Text color={color} fontWeight="bold">{monthlyCost}</Text>
                <Text
                  color={color}
                  fontSize="12px"
                  textAlign="center"
                >
                  {
                    growthPercentage ?
                      <><TriangleUpIcon color="red.500"/> {growthMonthlyCost}</>
                      :
                      '-'
                  }
                </Text>
              </Flex>
            </Flex>
          </ListItem>
          <Divider/>
          <ListItem>
            <Flex justifyContent="space-between">
              <Text>Lucro líquido mensal</Text>
              <Flex direction="column" gap={1}>
                <Text color={color} fontWeight="bold">{storeResult}</Text>
                <Text
                  color={color}
                  fontSize="12px"
                  textAlign="center"
                >
                  {
                    growthPercentage ?
                      <><TriangleUpIcon color="green.500"/> {growthStoreResult}</>
                      :
                      '-'
                  }
                </Text>
              </Flex>
            </Flex>
          </ListItem>
          <Divider/>
        </List>
      </CardBody>
    </Card>
  );
};