import './App.css';
import {Container} from "@chakra-ui/react";
import React from "react";
import {HeaderMenu} from "./components/HeaderMenu";
import {Features} from "./components/Features";
import {Pricing} from "./components/Pricing";
import {Benefits} from "./components/Benefits";
import {Hero} from "./components/Hero";
import {Footer} from "./components/Footer";
import {HowToStart} from "./components/HowToStart";
import {RoutineClient} from "./components/RoutineClient";
import {RoutineAuvi} from "./components/RoutineAuvi";
import {Calculator} from "./components/Calculator/Calculator";

function App() {
  return (
    <div className="App">
      <Container maxW='1440pt' p={0}>
        <HeaderMenu/>
        <Hero/>

        <Container maxW='1120pt' p={0}>
          <Benefits/>
          <Features/>
          {/*<Calculator/>*/}
          {/*<Pricing/>*/}
          <HowToStart/>
          <RoutineClient/>
          <RoutineAuvi/>
        </Container>

        <Footer/>
      </Container>
    </div>);
}

export default App;
