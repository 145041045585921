import {Divider, Flex, Heading, Hide, Img, Tag, Text, Tooltip} from "@chakra-ui/react";
import {InfoOutlineIcon} from "@chakra-ui/icons";
import React from "react";

export const RoutineClient = () => (
  <Flex
    id="dia-a-dia"
    p={{ base: 4, md: 8 }}
    flexDirection="column"
  >
    <Heading m="0 auto" pb={24}>Entenda o seu dia a dia com a Auvi</Heading>
    <Flex pb={12} gap={4}>
      <Heading as="h3" size="lg" color="blue.500">
        O que você precisa fazer
      </Heading>
      <Tag variant="outline" colorScheme="green" fontWeight="bold">MENOS DE 5 MINUTOS POR DIA!</Tag>
    </Flex>
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={12}
      pb={12}
    >
      <Text fontWeight="semibold" fontSize="20px">
        Envie os seus relatórios do ERP todas as manhãs <Hide below="md"><Tooltip hasArrow label="Precisamos dos relatórios: Base de clientes, Base de vendas com os clientes associados, Faturamento de produtos e Catálogo de produtos"><InfoOutlineIcon /></Tooltip></Hide>
      </Text>
      <Img src="card-send.png"/>
    </Flex>
    <Divider/>
  </Flex>
);