import {Box, Button, Flex, Heading, Img, Link, Text} from "@chakra-ui/react";
import {ArrowForwardIcon} from "@chakra-ui/icons";
import React from "react";

const StepsSection = ({title, images}) => (
  <>
    <Text fontWeight="semibold" fontSize="24px">
      {title}
    </Text>
    <Flex
      gap={4}
      justifyContent="center"
      flexDirection={{base: "column", sm: "column", md: "column", lg: "row"}}
    >
      {images.map((image) => (
        <Img
          w={{base: "100%", xs: "100%", md: "100%", lg: "32%"}}
          src={image}
        />
      ))}
    </Flex>
  </>
);

export const RoutineAuvi = () => (
  <Flex
    flexDirection="column"
    p={{base: 4, md: 8}}
  >
    <Flex py={8} gap={4}>
      <Heading as="h3" size="lg" color="blue.500">
        O que a Auvi faz por você
      </Heading>
    </Flex>

    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={12}
      pb={12}
    >
      <StepsSection
        title="Estabelecemos uma comunicação automática e recorrente com seus clientes"
        images={["routine-product-recommendation.png", "routine-reminder-cashback.png", "routine-advertise-promotions.png"]}
      />
      <StepsSection
        title="Gerenciamos o cashback de seus clientes"
        images={["routine-config-cashback.png", "routine-manage-cashback-balance.png", "routine-user-info.png"]}
      />
      <StepsSection
        title="Periodicamente encaminhamos sugestões de ações estratégicas para a loja"
        images={["routine-store-performance.png", "routine-new-portifolio.png", "routine-campaigns.png"]}
      />
    </Flex>
    <Link target="_blank" href="https://api.whatsapp.com/send/?phone=+5511914447843&text=Quero+testar+1+mês+grátis+na+Auvi!" m="0 auto">
      <Button rightIcon={<ArrowForwardIcon/>} size="lg" w="250px">Teste com 1 mês grátis</Button>
    </Link>
  </Flex>);