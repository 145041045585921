import {Box, Divider, Flex, Img, Text} from "@chakra-ui/react";
import React from "react";

export const Footer = () => (
  <Box bgColor="gray.100">
    <Flex px={{ base: 4, md: 32 }} py={12} maxW="600px" direction="column" gap={12}>
      <Img w="250px" src="logo-big.png"/>
      <Flex direction="column" gap={2}>
        <Text as="b">Contato:</Text>
        <Text>+55 11 91444-7843</Text>
        <Text>suporte@auvi.com.br</Text>
      </Flex>
    </Flex>
    <Flex px={32} pb={8} direction="column">
      <Divider mb={3}/>
      <Text as="small">© 2024 Auvi, Todos os direitos reservados</Text>
    </Flex>
  </Box>);