import {Flex, Img, Text} from "@chakra-ui/react";
import React from "react";

export const Benefits = () => (
  <Flex
    id="beneficios"
    justifyContent="space-evenly"
    alignItems="space-evenly"
    direction={{base: "column", sm: "column", md: "row"}}
    py={{base: 12, sm: 12, md: 48}}
    gap={{base: 12, sm: 12, md: 0}}
  >
    <Flex gap={5} alignItems="center" direction="column">
      <Img src="icon-1.png" w="68px"/>
      <Text align="center" maxW="250px">Aumente suas vendas mensais em até 20%</Text>
    </Flex>
    <Flex gap={5} alignItems="center" direction="column">
      <Img src="icon-2.png" w="68px"/>
      <Text align="center" maxW="250px">Crie relacionamentos duradouros com seus clientes</Text>
    </Flex>
    <Flex gap={5} alignItems="center" direction="column">
      <Img src="icon-3.png" w="68px"/>
      <Text align="center" maxW="250px">A cada R$1,00 investido na Auvi, você tem até R$10,00 de lucro</Text>
    </Flex>
  </Flex>
);