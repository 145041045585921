import {Box, Button, ButtonGroup, Flex, Heading, Link} from "@chakra-ui/react";
import {ArrowForwardIcon} from "@chakra-ui/icons";
import React from "react";

export const Hero = () => (
  <Box
    bgImg="hero.jpg"
    h="65vh"
    bgPosition="center"
    bgSize="cover"
    color="white"
  >
    <Flex pt={{base: 8, md: 24}} px={50} alignItems="center" direction="column" gap={8}>
      <Heading size="2xl" lineHeight={1.3} textAlign="center">
        A solução mais econômica e de menor esforço para impulsionar o seu faturamento
      </Heading>
      <ButtonGroup alignItems="center" flexDirection={{base: "column", md: "row"}} gap={{base: 4, md: 0}}>
        <Link href="#beneficios">
          <Button variant="outline" size="lg" color="white">Saiba mais</Button>
        </Link>
        <Link target="_blank" href="https://api.whatsapp.com/send/?phone=+5511914447843&text=Quero+testar+1+mês+grátis+na+Auvi!">
          <Button size="lg" rightIcon={<ArrowForwardIcon/>}>Teste com 1 mês grátis</Button>
        </Link>
      </ButtonGroup>
    </Flex>
  </Box>
);