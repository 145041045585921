import {Button, Flex, Heading, Img, Link, Show, Text} from "@chakra-ui/react";
import React from "react";
import {ArrowForwardIcon} from "@chakra-ui/icons";

export const FeatureSection = ({title, text, imageUrl, hasBackgroundColor = false, rightToLeft = false}) => (
  <Flex
    p={{base: 4, sm: 4, md: 4, lg: 8, xl: 16}}
    gap={16}
    alignItems="start"
    bg={hasBackgroundColor ? "#F4F7FA" : "white"}
    direction={rightToLeft ? "row-reverse" : "row"}
  >
    <Flex direction="column" gap={9} pt={{md: 0, lg: 8}}>
      <Heading align="left" size="lg">{title}</Heading>
      <Text fontSize="lg" align="left">{text}</Text>
      <Link target="_blank" href="https://api.whatsapp.com/send/?phone=+5511914447843&text=Quero+testar+1+mês+grátis+na+Auvi!">
        <Button
          ml={rightToLeft ? "unset" : "auto"}
          width="200px"
          rightIcon={<ArrowForwardIcon/>}
        >
          Teste Grátis
        </Button>
      </Link>
    </Flex>
    <Show above="md">
      <Img w="50%" src={imageUrl}/>
    </Show>
  </Flex>
);