import {Box} from "@chakra-ui/react";
import {FeatureSection} from "./FeatureSection";
import React from "react";

export const Features = () => (
  <Box id="funcionalidades">
    <FeatureSection
      title="Recomendações personalizadas com comunicações automatizadas"
      text="Com nossa Inteligência Artificial, ajudamos você a se conectar com seus clientes de forma ativa e automática via whatsapp. Enviamos recomendações personalizadas de produtos, avisos de promoções e novidades da loja"
      imageUrl="recommendation.png"
      hasBackgroundColor
    />

    <FeatureSection
      title="Cashback potencializado com Inteligência artificial"
      text="Com nosso programa de cashback, seus clientes sempre terão incentivos para voltar a comprar em sua loja"
      imageUrl="loyalty.png"
      rightToLeft
    />

    <FeatureSection
      title="Sugestões de ações estratégicas para potencializar seu crescimento"
      text="Tenha uma visão detalhada da performance de suas vendas e recomendações estratégicas para impulsionar seu faturamento"
      imageUrl="analytics.png"
      hasBackgroundColor
    />
  </Box>);