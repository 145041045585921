import {Button, Flex, Img, Link, Show} from "@chakra-ui/react";
import React from "react";

export const HeaderMenu = () => (
  <Flex
    py={8}
    px={4}
    justifyContent="space-between"
    alignItems="center"
    // direction={{ base: "column", md: "row"}}
    // gap={{ base: 4, md: 0}}
  >
    <Img h={{base: "20px", sm: "20px", md: "20px", lg: "40px"}} src="logo.png"/>

    <Show above="md">
      <Flex alignItems="center" gap={{sm: 2, md: 4, lg: 8}}>
        <Link href="#beneficios">Benefícios</Link>
        <Link href="#funcionalidades">Funcionalidades</Link>
        {/*<Link href="#precos-planos">Preços e Planos</Link>*/}
        <Link href="#primeiros-passos">Primeiros Passos</Link>
        <Link href="#dia-a-dia">Dia a dia de uso</Link>
      </Flex>
    </Show>

    <Flex alignItems="center" gap={4}>
      <Link href="https://painel.auvi.com.br">
        <Button size={{base: "sm", md: "md"}} variant="outline">Acessar conta</Button>
      </Link>
      {/*<Link href="#precos-planos">*/}
      {/*  <Button size={{base: "sm", md: "md"}}>*/}
      {/*    Teste Grátis*/}
      {/*  </Button>*/}
      {/*</Link>*/}
    </Flex>
  </Flex>
);